@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Poppins:wght@100;300;400;500;600;700;800&display=swap");

body {
  background: linear-gradient(
    176deg,
    rgb(18, 24, 27) 50%,
    rgb(32, 39, 55) 100%
  );
  min-height: 100vh;
  background-attachment: fixed;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-800 {
  font-weight: 800;
}

.font-600 {
  font-weight: 600;
}

.bg-black2 {
  background-color: rgb(18, 24, 27);
}

.shadow-3xl {
  --tw-shadow: 0 5px 20px rgb(0 0 0 / 30%);
  --tw-shadow-colored: 0 5px 20px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.nav-item .nav-link:hover {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #f97316;
  --tw-gradient-to: rgb(249 115 22 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #eab308;
  background-clip: text;
  color: #0000;
  -webkit-background-clip: text;
}

.nav-link-active {
  color: #eab308 !important;
}

.gradient-text {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #f97316;
  --tw-gradient-to: rgb(249 115 22 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #eab308;
  background-clip: text;
  color: #0000;
  -webkit-background-clip: text;
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.text-gray1 {
  --tw-text-opacity: 1;
  color: rgb(178 190 205 / var(--tw-text-opacity));
}

.text-gray2 {
  --tw-text-opacity: 1;
  color: rgb(18 24 27 / var(--tw-text-opacity));
}

.bg-gray3 {
  --tw-bg-opacity: 1;
  background-color: rgb(42 46 53 / var(--tw-bg-opacity));
}

.border-gray {
  --tw-bg-opacity: 1;
  border: 3px groove rgb(42 46 53 / var(--tw-bg-opacity));
}

.rounded-circle {
  border-radius: 50%;
}

.text-xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.face-img-holder {
  width: 300px;
  height: 300px;
  border: 3px solid #eab308;
  border-radius: 50%;
}

.face-img-holder img {
  object-fit: cover;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

.logo-holder {
  width: 80px;
  height: 80px;
  border-radius: 5px;
}

.logo-holder img {
  width: 60px;
}

.tech-icon {
  height: 60px;
}

.rounded {
  border-radius: 0.75rem;
}

.progress-holder {
  background: linear-gradient(
    176deg,
    rgb(18, 24, 27) 50%,
    rgb(32, 39, 55) 100%
  );
  height: 10px;
  width: 100%;
  border-radius: 0.75rem;
}

.progress-percent {
  height: 10px;
  border-radius: 0.75rem;
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #f97316;
  --tw-gradient-to: rgb(249 115 22 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #eab308;
}

.skill-holder {
  background-color: #555b66;
}

.skill-holder .icon {
  font-size: 40px;
  --tw-bg-opacity: 1;
  color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.map-holder {
  width: 100%;
  height: 400px;
  --tw-bg-opacity: 1;
  background-color: rgb(42 46 53 / var(--tw-bg-opacity));
}

.leaflet-container {
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
}

.border-touch {
  border-bottom-width: 4px;
  border-bottom-color: white;
}
.form-input {
  display: block;
  width: 100%;
  border-radius: 0px;
  border-bottom-width: 4px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(42 46 53 / var(--tw-bg-opacity));
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.leaflet-control-attribution {
  display: none !important;
}
